.aboutus-content-wrap {
    max-width: 1160px !important;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, .7);
    margin: 0 auto 100px;
    padding: 48px 100px;
    border-radius: 50px;
    position: relative;
}

.aboutus-content-wrap * {
    box-sizing: border-box;
}

.aboutus-content-wrap h2 {
    font: 700 32px/1.2 var(--font-montserrat);
    color: #FFFFFF;
    position: relative;
    margin-bottom: 90px;
    letter-spacing: 2px;
    text-align: center;
}

.aboutus-content-wrap h2::before {
    content: '';
    position: absolute;
    height: 5px;
    width: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -20px;
    display: block;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.aboutus-content-wrap .items-wrap {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.aboutus-content-wrap .items-wrap .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 36px;
}

.aboutus-content-wrap .items-wrap .item > div {
    flex: 1;
}

.aboutus-content-wrap .items-wrap .item .img-wrap img {
    width: 100%;
    border-radius: 16px;
}

.aboutus-content-wrap .items-wrap .item .text-wrap {
    color: #FFFFFF;
}

.aboutus-content-wrap .items-wrap .item .text-wrap h3 {
    font: 700 21px/1.2 var(--font-montserrat);
    margin: 0 0 20px;
    max-width: 330px;
}

.aboutus-content-wrap .items-wrap .item .text-wrap p {
    font: 400 13px/1.3 var(--font-montserrat);
    margin: 0 0 1.5em 0;
}

@media (max-width: 980px) {
    .aboutus-content-wrap {
        padding: 40px;
        border-radius: 0;
        margin-bottom: -10px;
    }

    .aboutus-content-wrap .items-wrap {
        gap: 40px;
    }
}

@media (max-width: 800px) {
    .aboutus-content-wrap {
        padding: 72px 24px;
    }

    .aboutus-content-wrap .items-wrap {
        gap: 72px;
    }

    .aboutus-content-wrap .items-wrap .item {
        flex-direction: column;
        gap: 30px;
    }

    .aboutus-content-wrap .items-wrap .item:last-child {
        flex-direction: column-reverse;
    }

    .aboutus-content-wrap .items-wrap .item > div {
        width: 100%;
    }

    .aboutus-content-wrap .items-wrap .item .text-wrap {
        padding: 0 14px;
    }
}
