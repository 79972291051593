.m-banner-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.m-banner-content-wrap {
    width: 100%;
    border-radius: 0 0 15px 15px;
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.m-banner-header {
    font: 900 40px/1.2 var(--font-montserrat);
    padding: 20px 20px;
    text-align: center;
    color: #000000;
}

.m-banner-header-sub {
    font: 400 28px/1.2 var(--font-montserrat);
    color: #282828;
}

.m-banner-img img {
    width: 100%;
}

.m-banner-buttons {
    gap: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0 30px 0;
    width: 100%;
}

.m-banner-buttons .m-banner-btn img {
    height: 62px;
}

.m-banner-buttons .m-banner-btn:first-child img {
    height: 73px;
}

.m-banner-wrap {
    position: relative;
    overflow: hidden;
    margin-top: 94px;
}

.m-banner-block {
    width: 100%;
    border-radius: 2px;
    background-position: 40% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.m-banner-img {
    height: 100%;
}

.m-banner-content-wrap {
    width: 100%;
    min-height: 380px;
    overflow: hidden;
    border-radius: 0 0 15px 15px;
    display: flex;
    justify-content: space-between;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    position: relative;
}

.m-banner-bg-wrap {
    z-index: 1;
    width: 100%;
    height: 100%;
}

.m-banner-bg-wrap .swiper {
    width: 100%;
    height: 100%;
}

.m-banner-bg-wrap .swiper-container .swiper-slide {
    box-sizing: border-box;
}

.m-banner-bg-wrap .swiper-button-prev {
    left: 30px;
}

.m-banner-bg-wrap .swiper-button-next {
    right: 30px;
}

.m-banner-bg-wrap .swiper-button-next, .m-banner-bg-wrap .swiper-button-prev {
    color: rgba(166, 166, 166, 0.3);
}

.m-banner-bg-wrap .swiper-button-next::after, .m-banner-bg-wrap .swiper-button-prev::after {
    font-size: 25px;
}

.m-banner-bg-wrap .swiper-button-next:hover, .m-banner-bg-wrap .swiper-button-prev:hover {
    color: rgba(166, 166, 166, 0.6);
}

.m-banner-bg-wrap .arrow-right, .m-banner-bg-wrap .arrow-left {
    width: 30px;
    height: 30px;
    opacity: 0.7;
    top: 45%;
}

.m-banner-img-wrap {
    height: 270px;
    width: 100%;
    z-index: 0;
    position: relative;
}

.m-banner-img-wrap img {
    max-width: 100%;
    max-height: 270px;
    object-fit: contain;
}

.m-banner-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    max-width: max-content;
}

.m-banner-bg-wrap .swiper-button-prev {
    left: 15px;
}

.m-banner-bg-wrap .swiper-button-next {
    right: 15px;
}

.m-banner-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-height: 180px;
    height: max-content;
    box-sizing: border-box;
}

.m-banner-header .m-banner-header-title {
    font: 800 32px/1 var(--font-montserrat);
    text-align: center;
    text-transform: uppercase;
}

.m-banner-header .m-banner-header-title img {
    max-height: 140px;
    width: auto;
}

.m-banner-header .m-banner-header-sub {
    font: 400 32px/1.1 var(--font-montserrat);
}

.m-banner-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
}

.m-banner-buttons .m-banner-btn {
    max-height: max-content;
}

.m-banner-buttons .m-banner-btn a {
    display: block;
    height: max-content;
    min-height: 48px;
}

.m-banner-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    bottom: 35px;
    position: absolute;
    right: 10px;
}

.m-banner-info .m-banner-info-headline {
    font: 700 12px/1.1 var(--font-montserrat);
    text-align: left;
    background-color: #FFF;
    padding: 4px 6px;
    color: #707070;
    margin-bottom: 8px;
    max-width: max-content;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.49);
}

.m-banner-info .m-banner-info-body {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
}

.m-banner-info .m-banner-info-body .m-banner-info-action-wrap a .main-button {
    padding: 8px 14px;
    max-width: max-content;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.m-banner-info .m-banner-info-body .m-banner-info-action-wrap a .main-button .main-button-text {
    font-size: 16px;
}

.m-banner-info .m-banner-info-body .m-banner-info-price-wrap {
    font: 700 16px/1.1 var(--font-montserrat);
    background-color: #FFF;
    padding: 0 4px;
    color: #000;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.22);
}
