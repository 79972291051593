.user-main-page-content {
    position: relative;
    display: flex;
    flex-direction: column;
}

.page-content-left {
    position: relative;
    width: 194px;
    margin-right: 16px;
    flex-shrink: 0;
}

.page-content-right {
    flex-grow: 1;
    width: 100%;
}

.page-content-right-banner {
    position: relative;
}

@media (max-width: 980px) {
    .page-content-right {
        margin-top: 60px;
    }
}

.win-stats-content-wrap {
    min-height: 932px;
    position: relative;
}