.m-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.m-header.sticky {
    position: fixed;
    transition: top 0.2s ease-in-out;
}

.m-header-placeholder {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: absolute;
    background-color: #ffffff;
    height: 94px;
}

.nav-up {
    top: -94px;
}

.m-header-with-views {
    top: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    min-height: 96px;
    max-width: 1440px;
    position: fixed;
    transition: top 0.15s ease;
}

.m-header-with-views.active {
    top: 0;
}

.m-header-center {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    max-width: max-content;
}

.m-header-center .main-button {
    padding: 13px 30px;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.m-header-center .main-button .main-button-text {
    font-size: 17px;
    line-height: 21px;
}

.m-header-views {
    cursor: pointer;
}

.m-header-logo img,
.m-header-logo svg {
    height: 50px !important;
    width: auto !important;
}

.m-header-burger {
    width: 56px;
    height: 45px;
    cursor: pointer;
}

.m-header-actions-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* width: 90px;
    height: 30px; */
}

.m-header-user-balance {
    margin-right: 12px;
    margin-left: 12px;
    font-family: var(--font-montserrat);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #272C4A;
}

.m-header-languages {
    padding: 10px;
}

.m-header-user {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.m-header-user-avatar {
    max-width: 31px;
    max-height: 32px;
    margin-right: 3px;
    margin-bottom: 4px;
}








