.base-popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 20;
    max-height: 100vh;
}

.popup-overlay {
    position: fixed;
    z-index: 19;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(36, 36, 36, 0.7);
}

.popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: max-content;
    width: max-content;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    z-index: 21;
}

.base-popup-close {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
    z-index: 10;
}

.base-popup-close img {
    width: 24px;
}

.base-popup-content {
    max-width: calc(100vw - 40px);
    max-height: 100vh;
    overflow: auto;
}

.base-popup-children {
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    overflow: hidden;
    background: #fff;
}

.base-popup-children > div {
    margin: unset;
}

@media (max-width: 980px) {
    .base-popup {
        right: 0;
    }
}

@supports (-ms-ime-align:auto) {
    .base-popup-children {
        width: 500px;
    }
}
