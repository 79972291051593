.header {
    height: 70px;
    padding: 12px 35px;
    background: #FFFFFF;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-with-views {
    top: -70px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    position: fixed;
    transition: top 0.15s ease;
}

.header-with-views.active {
    top: 0;
}

.header-left, .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-left {
    gap: 40px;
}

.header-right {
    gap: 20px;
    padding-left: 20px;
    justify-content: flex-end;
}

.header-stats-items {
    display: flex;
    align-items: stretch;
    gap: 20px;
    padding: 6px 12px;
    border: 2px solid #B90109;
    border-radius: 10px;
}

.header-left > .logo {
    color: #272C4A;
}

.header-center {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    max-width: max-content;
}

.header-center .main-button {
    padding: 13px 40px;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.header-center .main-button .main-button-text {
    font-size: 21px;
    line-height: 24px;
}

.header-balance-container {
    min-width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-balance {
    font: 400 10px/1.2 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    color: #7B7B7B;
}

.header-balance-value {
    font: 700 18px/1.2 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    color: #B90109;
}

.header-left .logo img,
.header-left .logo svg {
    width: auto !important;
    height: 40px !important;
}

.nav {
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-page {
    height: 100%;
    display: flex;
    align-items: center;
}

.header-right .user-actions a {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: relative;
    border-radius: 10px;
    padding: 5px 20px;
    border: 2px solid #B90109;
    box-sizing: border-box;
}

.header-right .user-actions .icon {
    display: flex;
    align-items: center;
}

.header-right .user-actions .text {
    font: 700 10px/1 'Montserrat', sans-serif;
}

.nav-login {
    font-size: 0.018rem;
    font-weight: 500;
    color: rgba(0, 15, 154, 0.85);
    padding: 0.007rem 0.007rem;
    cursor: pointer;
}

.user-panel {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 10px;
}

.user-panel-info {
    display: flex;
    align-items: center;
}

.user-panel-info-link {
    display: flex;
    align-items: center;
}

.user-panel-info-left {
    height: 32px;
    width: 32px;
    border: 2px solid #B90109;
    border-radius: 50%;
    overflow: hidden;
}

.user-panel-info-avatar {
    height: 100%;
}

.user-panel-info-right-name {
    max-width: 100px;
    color: var(--color-dark-blue);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.user-panel-btn-logout {
    width: 34px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-panel-btn-logout img {
    height: 24px;
}

.user-panel-btn-add-funds .main-button {
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.user-panel-btn-add-funds .main-button-text span {
    font-size: 16px;
}

.user-panel-btn-add-funds:hover img {
    animation: icon_shake 0.5s ease both;
}

.user-header-caption {
    font-family: var(--font-montserrat);
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.header-left .users-online {
    font: 400 10px/1 'Montserrat', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.header-left .users-online .num {
    font: 700 12px / 1 'Montserrat', sans-serif;
    color: #B90109;
}

.header-left .users-online .text {
    color: #272727;
}

.header-left .users-online img {
    height: 20px;
}

@media (max-width: 1200px) {
    .header {
        padding: 12px 15px;
    }

    .header-left {
        gap: 20px;
    }

    .header-right {
        padding-left: 15px;
        gap: 15px;
    }

    .user-panel {
        gap: 15px;
        margin-left: 0;
    }

    .header-stats-items {
        gap: 15px;
    }
}
