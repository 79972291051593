.popup-title {
    text-align: center;
    border-radius: 10px;
    padding: 60px 30px 45px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.popup-title-text {
    font: 600 28px/1.2 var(--font-montserrat);
    color: #60676E;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.popup-title-block {
    width: max-content;
}

.popup-title-content {
    margin-top: 18px;
    width: 100%;
    /* padding: 0 80px 48px; */
    box-sizing: border-box;
    font-size: 14px;
    color: #5F6B7A;
    font-family: var(--font-montserrat);
    font-style: normal;

}

@media (max-width: 980px) {
    .popup-title {
        min-width: 230px;
        max-width: 100%;
    }

    .popup-title-text {
        font-size: 24px;
    }

    .popup-title-content {
        width: 100%;
        font-size: 14px;
        color: #5F6B7A;
        font-family: var(--font-montserrat);
        font-style: normal;
    }
}
