.reviews-content-wrap .reviews-title-wrap {
    text-align: center;
}

.reviews-content-wrap .reviews-title-wrap h2 {
    font: 700 32px/1.2 var(--font-montserrat);
    color: #FFFFFF;
    position: relative;
    margin-bottom: 65px;
    letter-spacing: 2px;
}

.reviews-content-wrap .reviews-title-wrap h2::before {
    content: '';
    position: absolute;
    height: 5px;
    width: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -20px;
    display: block;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.reviews-content-wrap .reviews-videos-wrap {
    text-align: center;
    margin: 48px auto;
    padding: 0 15px;
    position: relative;
    max-width: 1000px;
    z-index: 2;
}

.reviews-content-wrap .reviews-videos-wrap  {
    width: 100%;
    height: 100%;
    padding-bottom: 56px;
}

.reviews-content-wrap .reviews-videos-wrap .blaze-slider {
    padding: 0 10px;
    box-sizing: border-box;
}

.reviews-content-wrap .reviews-videos-wrap .blaze-slider video {
    height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;
}

.reviews-content-wrap .reviews-videos-wrap .slick-dots {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.reviews-content-wrap .reviews-videos-wrap .slick-dots li {
    display: inline-block;
    margin: 5px;
}


.reviews-content-wrap .reviews-videos-wrap .blaze-pagination button {
    width: 14px;
    height: 14px;
    border: 2px solid #AD9952;
    margin: 10px 5px;
    box-sizing: border-box;
    background-color: transparent;
    opacity: 1;
    border-radius: 7px;
    color: #0000;
}

.reviews-content-wrap .reviews-videos-wrap .blaze-pagination button.active  {
    background-color: #AD9952;
}

.reviews-action-box-btn-wrap {
    max-width: max-content;
    margin: 0 auto 100px;
    position: relative;
    z-index: 2;
}

.reviews-action-box-btn-wrap .main-button {
    padding: 20px 40px;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.reviews-action-box-btn-wrap .main-button-text {
    font-size: 21px;
}

@media (max-width: 1320px) {

}

@media (max-width: 980px) {
    .reviews-content-wrap .reviews-title-wrap h2 {
        font-size: 40px;
    }
}

@media (max-width: 640px) {
    .reviews-content-wrap .reviews-videos-wrap {
        padding: 0;
        margin: 48px auto 24px;
    }

    .reviews-content-wrap .reviews-videos-wrap .swiper-slide:not(.swiper-slide-active) {
        opacity: .7;
    }
}