.banner-wrap {
    position: relative;
    overflow: hidden;
}

.banner-block {
    height: 249px;
    width: 100%;
    border-radius: 2px;
    background-position: 40% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner-img {
    height: 100%;
}

.banner-content-wrap {
    width: 100%;
    height: 380px;
    overflow: hidden;
    border-radius: 0 0 15px 15px;
    display: flex;
    justify-content: space-between;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    position: relative;
}

.banner-bg-wrap {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.banner-bg-wrap .blaze-slider {
    width: 100%;
    height: 100%;
}

.banner-img-wrap {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.banner-img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left center;
}

.banner-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 80px;
    position: relative;
    z-index: 2;
    max-width: max-content;
}

@media (max-width: 1200px) {
    .banner-bg-wrap .swiper-button-prev {
        left: 15px;
    }

    .banner-bg-wrap .swiper-button-next {
        right: 15px;
    }

    .banner-center {
        margin: 0 60px;
    }
}

.banner-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: center;
}

.banner-header .banner-header-title {
    font: 800 45px/1 var(--font-montserrat);
    width: 520px;
    text-align: center;
    text-transform: uppercase;
}

.banner-header .banner-header-title img {
    max-width: 100%;
}

.banner-header .banner-header-sub {
    font: 400 32px/1.1 var(--font-montserrat);
}

.banner-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    max-width: max-content;
}

.banner-buttons .banner-btn {
    max-height: max-content;
    cursor: pointer;
}

.banner-buttons .banner-btn a {
    display: block;
    height: max-content;
    min-height: 48px;
}

.banner-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    text-align: center;
    bottom: 35px;
    right: 70px;
}

.banner-info .banner-info-headline {
    font: 700 19px/1.2 var(--font-montserrat);
    background-color: #FFF;
    padding: 6px 12px;
    color: #707070;
    margin-bottom: 14px;
    max-width: max-content;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.49);
}

.banner-info .banner-info-body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.banner-info .banner-info-body .banner-info-action-wrap a .main-button {
    padding: 12px 20px;
    max-width: max-content;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.banner-info .banner-info-body .banner-info-action-wrap a .main-button .main-button-text {
    font-size: 20px;
}

.banner-info .banner-info-body .banner-info-price-wrap {
    font: 700 20px/1.2 var(--font-montserrat);
    background-color: #FFF;
    padding: 0 8px;
    color: #000;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.22);
}

@media (max-width: 1200px) {
    .banner-info .banner-info-headline {
        max-width: 270px;
    }

    .banner-info .banner-info-body {
        flex-direction: column;
    }
}

.slick-arrow {
    display: none !important;
}

.slick-slider .slick-track {
    height: 100%;
    position: static;
}

.banner-bg-wrap .blaze-track {
    position: relative;
    height: 100%;
}

.banner-bg-wrap .blaze-container {
    height: 100%;
}

.banner-bg-wrap .blaze-track-container {
    height: 100%;
}

.banner-bg-wrap .blaze-track > div {
    height: 100%;
    position: relative;
}

.slick-list {
    height:100%;
}

.arrow-left, .arrow-right {
    position: absolute;
    height: 60px;
    width: 60px;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.3;
}

.arrow-left:hover, .arrow-right:hover {
    opacity: 0.6;
}

.arrow-left {
    left: 0px;
    background-image: url("../../img/icons/arrow_left.svg?inline");
    
}

.arrow-right {
    right: 0px;
    background-image: url("../../img/icons/arrow_right.svg?inline");
}

