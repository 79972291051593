/*@font-face {
    font-family: 'Roboto';
    src: local('Roboto Thin'), local('Roboto-Thin'), url('../fonts/roboto/Roboto-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Light'), local('Roboto-Light'),url('../fonts/roboto/Roboto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}*/
/*@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto-Regular'), url('../fonts/roboto/Roboto-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Medium'), local('Roboto-Medium'),url('../fonts/roboto/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}*/
/*@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/roboto/Roboto-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Black'), local('Roboto-Black'),url('../fonts/roboto/Roboto-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}*/

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Black'), local('Montserrat-Black'),url('../fonts/montserrat/Montserrat-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'),url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Medium'), local('Montserrat-Medium'),url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Regular'), local('Montserrat-Regular'),url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'),url('../fonts/montserrat/Montserrat-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin'), local('Montserrat-Thin'),url('../fonts/montserrat/Montserrat-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
