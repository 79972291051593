.box-btn-wrap {
    margin: 50px auto 30px;
    max-width: max-content;
}

.box-btn-wrap .main-button {
    padding: 14px 55px;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.box-btn-wrap .main-button .main-button-text {
    font: 700 21px/1.2 var(--font-montserrat);
}

.box-list {
    margin-top: 20px;
    margin-bottom: 40px;
}

.box-list-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    column-gap: 12px;
    row-gap: 60px;
}

@media (max-width: 980px) {
    .box-list {
        padding: 50px 15px 100px;
    }
    
    .product-box-list {
        padding: 0 15px 20px;
    }
    
    .box-list-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        column-gap: 10px;
        row-gap: 25px;
    }    
}