.partners-content-wrap .partners-logos-wrap {
    text-align: center;
    max-width: 1100px;
    margin: 0 auto 110px;
    position: relative;
    z-index: 2;
}

.partners-content-wrap .partners-logos-wrap h2 {
    font: 700 32px/1.2 var(--font-montserrat);
    color: #FFFFFF;
    position: relative;
    margin-bottom: 65px;
    letter-spacing: 2px;
}

.partners-content-wrap .partners-logos-wrap h2::before {
    content: '';
    position: absolute;
    height: 5px;
    width: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -20px;
    display: block;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.partners-content-wrap .partners-logos-wrap .items-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.partners-content-wrap .partners-logos-wrap .items-wrap img {
    max-height: 115px;
    max-width: 205px;
}

.partners-content-wrap .partners-banner-wrap {
    margin-top: -120px;
}

.partners-content-wrap .partners-banner-wrap img {
    width: 100%;
}

.partners-banner-box-btn-wrap {
    max-width: max-content;
    margin: -150px auto 95px;
    position: relative;
    z-index: 2;
}

.partners-banner-box-btn-wrap .main-button {
    padding: 20px 40px;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.partners-banner-box-btn-wrap .main-button-text {
    font-size: 21px;
}

@media (max-width: 980px) {
    .partners-content-wrap .partners-logos-wrap h2 {
        font-size: 40px;
        margin-bottom: 85px;
    }

    .partners-content-wrap .partners-logos-wrap .items-wrap {
        flex-direction: column;
        gap: 48px;
    }

    .partners-content-wrap .partners-logos-wrap .items-wrap img {
        max-height: 100px;
        max-width: 180px;
    }

    .partners-content-wrap .partners-banner-wrap {
        margin-top: -100px;
    }

    .partners-banner-box-btn-wrap {
        margin: -50px auto 95px;
    }

    .partners-banner-box-btn-wrap .main-button {
        padding: 25px 80px;
    }
}

@media (max-width: 640px) {
    .partners-content-wrap .partners-banner-wrap img {
        width: 200%;
        margin-left: -50%;
    }
}
