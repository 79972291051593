.box-item,
.box-item-sale {
    --transition: 0.1s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: var(--transition);
    border-radius: 12px;
}

.box-item-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    margin-top: auto;
}

.box-item-price .main-button {
    margin-top: 6px;
}

.box-item-img {
    text-align: center;
    position: relative;
    width: 100%;
    height: 190px;
    margin-bottom: 8px;
}

@media (max-width: 980px) {
    .box-item-img {
        height: auto;
    }
}

.box-item-img > img {
    width: 100%;
    aspect-ratio: 1;
}

.box-item-desc {
    font: 700 14px/1.1 var(--font-montserrat);
    text-align: center;
    color: #60676E;
    margin-bottom: 15px;
}

.box-item-price .box-item-price-new {
    font: 700 18px/1.1 var(--font-montserrat);
    text-transform: uppercase;
    color: #292929;
}

.box-item-price .main-button {
    padding: 10px 28px;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.box-item-back {
    height: 40px;
    border-radius: 10px;
    width: 100%;
    margin-top: 5px;
    background-image: linear-gradient(339.22deg, #d127ed -81.59%, #36dbff 100.02%);
}

.box-item-open, .box-item-open-on {
    font-family: var(--font-montserrat);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    /* background-image: linear-gradient(339.22deg, #d127ed -81.59%, #36dbff 100.02%); */
    background: linear-gradient(339.22deg, #d127ed -81.59%, #36dbff 100.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 2px solid #78affb;

    height: 36px;
    border-radius: 10px;
    /* border: 2px solid; */
    line-height: 36px;
    width: 100%;
    margin-top: 5px;
    text-transform: uppercase;
    z-index: 1;
}

.box-item:hover {
    box-shadow: 0 10px 60px rgba(255, 255, 255, .25);
}

.box-item-sale:hover {
    box-shadow: 0 10px 60px rgba(255, 255, 255, .25);
}

.box-item-img-sale-tag {
    position: absolute;
    top: 0;
    left: 0;
    width: 79px;
    height: 79px;
}

.box-item-img-radial,
.box-item-img-radial-sale {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    background: radial-gradient(rgba(3, 0, 221, 0.2) 0%, rgba(3, 0, 221, 0) 72%);
    z-index: -1;
    transition: opacity var(--transition);
}

.box-item-img-radial-sale {
    background: radial-gradient(rgba(255, 0, 0, 0.2) 0%, rgba(196, 196, 196, 0) 72%);
}

.box-item-title {
    font-weight: 500;
    font-size: 24px;
    color: #242424;
    margin-bottom: 16px;
    text-align: center;
}

.box-item-price-block {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    transition: opacity var(--transition);
}

.box-item-btn-block {
    position: relative;
    width: 100%;
    height: 46px;
    margin: auto 0;
    opacity: 0;
    transition: opacity var(--transition);
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;
}

.box-item:hover .box-item-price-block {
    opacity: 0;
}

.box-item:hover .box-item-btn-block {
    opacity: 1;
}

.box-item:hover .box-item-img-radial,
.box-item:hover .box-item-img-radial-sale {
    opacity: 1;
}

.box-item-img:hover .main-button {
    font-size: 12px;
}

.img-normal {
    border: none;
}

.box-item:hover .img-normal {
    display: none;
}

.box-item:hover .img-hover {
    display: unset;
}

.img-hover {
    display: none;
}
