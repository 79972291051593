.main-button{
    /* height: 100%;
    width: 100%; */
    font-size: inherit;
    font-weight: inherit;
    border-radius: 4px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: box-shadow 100ms ease-in-out;
    text-transform: inherit;
}
.main-button:hover{
    box-shadow: 2px 8px 15px 2px rgba(0,0,0,0.3);
}
.main-button-primary{
    color: #ffffff;
    cursor: pointer;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
    border-radius: 100px;
    padding: 8px 16px 8px 16px;
}
.main-button-disabled{
    font-size: inherit;
    font-weight: inherit;
    border-radius: 4px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: box-shadow 100ms ease-in-out;
    text-transform: inherit;
    border-radius: 28px;    
    padding: 8px 16px 8px 16px;

	color: #ffffff;
	/* background: linear-gradient(90deg, rgba(0, 15, 154, 0.85) 2.71%, #6049FF 100%); */
	background: #B8B8B8;
    cursor: not-allowed;
    /* opacity: 0.4; */
}

.main-button-gray {
	box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.21);
    backdrop-filter: blur(4px);
    border-radius: 28px;    
    padding: 8px 16px 8px 16px;
}
.main-button-transparent-gray {
	box-sizing: border-box;
	color: #B8B8B8;
	border: 3px solid #B8B8B8;
    cursor: pointer;
    background: #FFFFFF;
    backdrop-filter: blur(4px);
    border-radius: 28px;    
    padding: 8px 16px 8px 16px;
}
.main-button-disabled:hover{
    box-shadow: none;
}
.main-button-transparent{
    color: var(--color-dark-blue);
    background: transparent;
    cursor: pointer;
}
.main-button-transparent:hover{
    text-shadow: 1px 1px 2px rgba(96, 73, 255, 0.3);
    box-shadow: none;
}
.main-button-text{
    height: 100%;
    text-align: center;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
	text-transform: uppercase;
	
	font-family: var(--font-montserrat);
	font-style: normal;
	font-weight: bold;
}
.main-button-loader{
    height: 100%;
}
.main-btn-event-none{
    pointer-events: none;
}
