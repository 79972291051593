.login-popup-content {
    margin: auto;
    width: 867px;
    padding: 33px 63px;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-color: #ffffff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
}

@media (max-width: 980px) {
    .login-popup-content {
        width: calc(100vw - 32px);
        min-width: 230px;
        max-width: 500px;
        padding: 33px 32px;
    }
}
