.video-container {
  aspect-ratio: 9 / 16;
  position: relative;
  width: 100%;
  max-width: 640px;
  margin: auto;
  background-color: #333333;
  border-radius: 20px;
}

.video-element {
  width: 100%;
  height: auto;
  z-index: -1;
  display: block;
}

.play-button-shade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.play-button-shade img {
  opacity: 0.8;
}

.play-button-shade:hover img {
  opacity: 1.0;
}