.map-content-wrap {
    position: relative;
    max-width: 100% !important;
}

.map-content-wrap .img-wrap {
    width: 100%;
    position: relative;
    z-index: 0;
}

.map-content-wrap .img-wrap img {
    display: block;
    width: 100%;
    height: auto;
}

.map-content-wrap .card-wrap {
    color: #FFFFFF;
    position: absolute;
    text-align: center;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 45%;
    margin: auto;
    height: max-content;
    max-width: max-content;
    border-radius: 10px;
    padding: 36px 32px 56px;
    background-image: linear-gradient(0deg, rgba(134, 3, 3, 1) 0%, rgba(188, 6, 13, 1) 100%);
}

.map-content-wrap .card-wrap h2 {
    font: 700 32px/1.2 var(--font-montserrat);
    margin-bottom: 40px;
    letter-spacing: 2px;
}

.map-content-wrap .card-wrap .links-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
}

.map-content-wrap .card-wrap .links-wrap p {
    font: 700 20px/1.2 var(--font-montserrat);
    margin-bottom: 10px;
}

.map-content-wrap .card-wrap .links-wrap a {
    font: 500 17px/1.5 var(--font-montserrat);
}

.map-content-wrap .card-wrap .btn-wrap .main-button {
    padding: 20px 40px;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.map-content-wrap .card-wrap .btn-wrap .main-button .main-button-text {
    font-size: 21px;
}

@media (max-width: 980px) {
    .map-content-wrap .img-wrap {
        border-radius: 10px 10px 0 0;
        overflow: hidden;
    }

    .map-content-wrap .img-wrap img {
        height: auto;
        width: 100%;
    }

    .map-content-wrap .card-wrap {
        position: relative;
        max-width: 100%;
        margin-top: -10px;
        padding: 36px 32px 100px;
        margin-bottom: -10px;
    }

    .map-content-wrap .card-wrap .btn-wrap .main-button {
        max-width: 350px;
        margin: 0 auto;
        padding: 28px 80px;
    }
}

.map--info-box--title {
    color: #DD010A;
}

.map--info-box--content {
    max-width: 220px;
    text-align: center;
}

.gm-style .gm-style-iw-tc::after {
    background: none;
    box-shadow:none;
}

.map-wrap {
    height: 500px;
}