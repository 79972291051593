:root {
    --color-dark-blue: #2d35a8;
    --color-light-blue: #6049FF;
    --color-grey: #757575;
    --color-dark-yellow: #FF6E00;
    --min-desktop-width: 980px;
    --font-family: Roboto, sans-serif;
    --font-montserrat: 'Montserrat', sans-serif;
    --font-roboto: 'Roboto', sans-serif;
}

#root {
    background-image: linear-gradient(180deg, #270002 0%, #BC060D 51.56%, #6F0000 90.72%);
}

.btn-blue {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    /* background: linear-gradient(90deg, rgba(0, 15, 154, 0.85) 2.71%, #6049FF 100%); */
    border-radius: 4px;
    /*padding: 7px 18px;*/
    border: none;
    cursor: pointer;
}

.btn-blue > .main-button {
    width: 100%;
    margin-right: 0px;
}

.btn-blue > img {
    display: block;
    margin-right: 10px;
}

.btn-blue:hover {
    /*text-decoration: underline;*/
}

/*.btn-blue:hover > img{
    animation: icon_shake 0.5s ease both;
}*/

.btn-white {
    text-transform: uppercase;
    color: var(--color-dark-blue);
    border: 1px solid var(--color-dark-blue);
    background: #ffffff;
    border-radius: 4px;
}

a {
    text-decoration: none;
    color: inherit;
}

body {
    padding: 0;
    margin: 0;
    font-family: var(--font-montserrat);
    font-size: 16px;
}

p {
    margin: 0;
}

h2 {
    margin: 0;
}

ul {
    padding: 0;
    margin: 0;
}

h1 {
    margin: 0;
}

button {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

select, select * {
    font-size: 14px;
}

@keyframes icon_shake {
    0% {
        transform: rotateZ(0);
    }
    16.66% {
        transform: rotateZ(-30deg);
    }
    50% {
        transform: rotateZ(20deg);
    }
    83.33% {
        transform: rotateZ(-10deg);
    }
    100% {
        transform: rotateZ(0);
    }
}

.is-scrolling {
    pointer-events: none;
}

.hidden {
    visibility: hidden;
}

.m-topline-label {
    font: 700 18px/1.2 var(--font-montserrat);
    text-transform: uppercase;
    color: #FFFFFF;
}

.m-topline-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    height: 50px;
    max-width: max-content;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: 12px;
    overflow: hidden;
    padding: 0 32px;
    margin: 32px auto 0;
    background-color: rgba(255, 255, 255, .2) !important;
}

.logo > img {
    height: 40px;
}

@media (max-width: 980px) {
    .logo > img {
        height: 50px;
    }
}

.preloader-wrap-popup {
    width: 250px;
    height: 250px;
}