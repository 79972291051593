.page {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.page .header {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.page-content {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    background-color: transparent;
}

.page-content > div {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 980px) {
    .page-content {
        min-height: unset;
    }
}
