.product-popup-content.popup-views {
    text-align: center;
    border: 5px solid #CDAF46;
    padding: 120px 50px 90px;
    max-width: 320px;
}

.popup-views-icon {
    margin-bottom: 48px;
}

.popup-views-icon img {
    width: 96px;
}

.popup-views-num {
    font: 900 40px/1.2 var(--font-roboto);
    margin-bottom: 12px;
    color: #B90109;
}

.popup-views-text {
    font: 500 17px/1.2 var(--font-montserrat);
    color: #7B7B7B;
}

.popup-views-eye {
    width: 120px;
    margin: 0 auto;
}