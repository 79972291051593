footer {
    position: relative;
    width: 100%;
    background-color: #520106;
}

.footer-logo-box {
    position: absolute;
    left: 52px;
    bottom: 355px;
}

.footer-logo {
    height: min-content;
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}

.footer-logo-name {
    float: left;
    padding-top: 48px;
    padding-left: 13px;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: rgba(255, 255, 255, 0.9);
}

.footer-content-container {
    padding: 50px;
    max-width: 1620px;
    margin: 0 auto;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .footer-content-container {
        padding: 40px 20px;
    }
}

.footer-content {
    width: 100%;
    max-height: 417px;
    position: relative;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

footer .logo {
    max-width: max-content;
    margin-bottom: 8px;
}

.footer-content-support {
    color: #FFFFFF;
    margin-bottom: 24px;
}

.footer-content-support-title {
    font: 500 14px/1.5 var(--font-montserrat);
}

.footer-content-support-number {
    font: 700 20px/1.5 var(--font-montserrat);
}

.footer-content-copyright {
    font: 500 14px/1.5 var(--font-montserrat);
    color: #FFFFFF;
}

.footer-content-follow {
    display: flex;
    flex-direction: column;
    font-family: var(--font-montserrat);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: #FFFFFF;
}

.footer-content-follow-caption {
    margin-top: 39px;
    margin-bottom: 6px;
}

.footer-content-follow-links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-content-follow-link {
    margin-left: 24px;
}

.footer-content-follow-link:first-of-type {
    margin-left: 0;
}

.footer-content-links-container {
    display: flex;
    flex-direction: column;
}

.footer-content-link-title {
    font: 700 20px/1.2 var(--font-montserrat);
    margin: 6px 0 12px;
    color: #FFFFFF;
}

.footer-content-links {
    color: #FFFFFF;
    flex-direction: column;
    justify-content: space-between;
}

.footer-content-links a {
    font: 400 14px/2.2 var(--font-montserrat);
    padding-right: 24px;
}

.footer-content-custom-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row dense;
}

.footer-content-custom-links > div:nth-child(-n+4) {
    grid-column-start: 1;
}

.footer-content-custom-links > div:nth-child(n+4) {
    grid-column-start: 2;
}

.footer-content-payment-container {
    display: flex;
    flex-direction: column;
}

.footer-content-payments {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 1200px) {
    .footer-content-payments {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.footer-content-payment {
    margin-left: -12px;
    width: 84px;
}

.footer-content-socials {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 10px;
}