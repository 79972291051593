footer {
    position: relative;
    width: 100%;
    background-color: #520106;
}

.m-footer-content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    box-sizing: border-box;
}

.m-footer-logo {
    margin-bottom: 40px;
}

.m-footer-logo .logo {
    display: flex;
    margin-bottom: 0;
}

.m-footer-logo .logo img {
    height: 60px;
}




.m-footer-content-container {
    padding: 50px;
    max-width: 1620px;
    margin: 0 auto;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .m-footer-content-container {
        padding: 40px 20px;
    }
}

.m-footer-content-links-container {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
}

.m-footer-content-link-title {
    font: 700 24px/1.2 var(--font-montserrat);
    margin: 0 0 16px;
    color: #FFFFFF;
}

.m-footer-content-links {
    font: 400 18px/2 var(--font-montserrat);
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.m-footer-content-payment-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 15px;
}

.m-footer-content-payment-container .m-footer-content-link-title {
    margin-bottom: 24px;
}

.m-footer-content-payments {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.m-footer-content-support {
    color: #FFFFFF;
    margin-bottom: 24px;
    text-align: center;
}

.m-footer-content-support-title {
    font: 500 18px/2 var(--font-montserrat);
}

.m-footer-content-support-number {
    font: 700 28px/1.5 var(--font-montserrat);
}

.m-footer-content-copyright {
    font: 500 18px/1.5 var(--font-montserrat);
    color: #FFFFFF;
}

.m-footer-content-socials {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 60px;
}

.m-footer-content-socials .footer-content-social img {
    height: 34px;
    width: auto;
}