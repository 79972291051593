.popup-payment-success {
    max-width: 90vw;
    width: 320px;
    text-align: center;
    padding: 60px 20px;
}

.popup-payment-success .popup-success-title {
    padding: 60px 40px;
}

.popup-payment-success .text-red {
    color: #B90109;
    font-weight: 700;
}

.popup-payment-success .payment-success-content {
    color: #7B7B7B;
    font-size: 18px;
}
