.mobile-menu-bg {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    transition: opacity 0.2s ease, visibility 0s 0.2s;
}

.mobile-menu-bg.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s ease, visibility 0s 0s;
}

.mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 320px;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /* background-image: url("../../svg/mobile_menu_bg.svg"); */
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #ffffff;
    z-index: 10;
    transform: translateX(-100%);
    transition: transform 0.2s ease;
    overflow-y: auto;
}

.mobile-menu:not(.show) {
    visibility: hidden;
}

.mobile-menu.show {
    transform: translateX(0);
}

.m-user-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px 16px;
}

.m-user-actions a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: relative;
    border-radius: 20px;
    padding: 16px 32px;
    border: 3px solid #B90109;
    box-sizing: border-box;
}

.m-user-actions .icon {
    display: flex;
    align-items: center;
}

.m-user-actions .icon img {
    width: 30px;
}

.m-user-actions .text {
    font: 700 18px/1 var(--font-montserrat);
    color: #282828;
}

.m-user-actions-item {
    width: 192px;
    height: 40px;
    margin-bottom: 18px;
    font-size: 18px;
}

.m-user-actions-item:last-child {
    margin-bottom: 0;
}

.m-user-panel-btn-logout {
    position: absolute;
    top: 36px;
    right: 24px;
}

.m-user-panel-btn-logout img {
    height: 24px;
}

.mobile-menu .m-header-logo {
    padding: 20px 24px;
    border-bottom: 1px solid #D9D9D9;
}

.mobile-menu .m-nav-list {
    border-bottom: 1px solid #D9D9D9;
    padding: 20px 0;
}

.mobile-menu .m-nav-stats-list {
    padding: 20px 36px;
    border-bottom: 0;
}

.mobile-menu .m-nav-stats-list .m-nav-stats-list-item {
    display: flex;
    align-items: center;
    gap: 6px;
}

.mobile-menu .m-nav-stats-list .m-nav-stats-list-item .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
}

.mobile-menu .m-nav-stats-list .m-nav-stats-list-item .icon-wrap img {
    width: 38px;
}

.mobile-menu .m-nav-stats-list .m-nav-stats-list-item .icon-wrap.icon-large-wrap img {
    width: 50px;
}

.mobile-menu .m-nav-stats-list .m-nav-stats-list-item .text-wrap {
    display: flex;
    flex-direction: column;
}

.mobile-menu .m-nav-stats-list .m-nav-stats-list-item .text-wrap.row {
    flex-direction: row;
}

.mobile-menu .m-nav-stats-list .m-nav-stats-list-item .text-wrap .value {
    font: 700 18px/1 var(--font-montserrat);
    display: flex;
    align-items: center;
    color: #B90109;
}

.mobile-menu .m-nav-stats-list .m-nav-stats-list-item .text-wrap .text {
    font: 400 14px/1 var(--font-montserrat);
    display: flex;
    align-items: center;
    color: #7B7B7B;
}

.mobile-menu .m-nav-list .nav-page-item {
    height: 36px;
    padding: 0 36px;
    text-decoration: none;
}

.mobile-menu .m-nav-list .nav-page-item span {
    line-height: 24px;
}

.nav-page-item {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 32px;

    font-family: var(--font-montserrat);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 62px;
    color: #272C4A;
    /* font-size: 17px;
    font-weight: 500;
    color: var(--color-grey); */
}

.nav-page-item:hover {
    text-decoration: underline;
}
