.header-with-views .user-header-info-data {
    flex-direction: row;
    padding-top: 8px;
    gap: 6px;
}

.header-with-views .user-header-info-data .user-header-info-caption {
    font-weight: 500;
}


.user-header-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.user-header-info .user-header-info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
}

.user-header-info .user-header-info-icon img,
.user-header-info .user-header-info-icon svg {
    max-width: 32px;
}

.user-header-info .user-header-info-icon > div {
    display: flex;
    align-items: center;
}

.user-header-info-data {
    display: flex;
    flex-direction: column;
}

.user-header-info-data .user-header-info-value {
    font: 700 12px/1 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    color: #B90109;
}

.user-header-info-data .user-header-info-caption {
    font: 400 10px/1 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    color: #272727;
}

@media (max-width: 980px) {
    .nav-page-item:hover .user-header-info-icon img {
        animation: icon_shake 0.5s ease both;
    }

    .m-header-with-views .user-header-info {
        align-items: center;
        gap: 8px;
    }
    
    .m-header-with-views .user-header-info-data {
        flex-direction: row;
        padding-top: 8px;
        gap: 6px;
    }

    .m-header-views .user-header-info .user-header-info-icon img,
    .m-header-views .user-header-info .user-header-info-icon svg {
        width: 45px;
        max-width: 45px;
    }

    .m-header-views .user-header-info-data .user-header-info-value {
        font-size: 19px;
    }
}
