.how-section {
    padding: 0;
    max-width: 1000px !important;
    min-height: 1040px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, .7);
    margin: 0 auto 90px;
    border-radius: 50px;
    position: relative;
}

.how-stars {
    top: -40px;
    left: -75px;
    position: absolute;
    z-index: 0;
}

.how-stars img {
    width: 1180px;
}

.how-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
    gap: 120px;
    z-index: 2;
    position: relative;
}

.how-tabs-content {
    z-index: 2;
    position: relative;
}

.how-tabs .how-title {
    font: 700 32px/1 var(--font-montserrat);
    position: relative;
    color: #FFFFFF;
    letter-spacing: 2px;
    cursor: pointer;
}

.how-tabs .how-title.active {
    color: rgba(255, 255, 255, .5);
}

.how-tabs .how-title.active::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 100%;
    left: 0;
    bottom: -20px;
    display: block;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.how-tab-content {
    z-index: 2;
    position: relative;
}

.how-tab-content-wrap {
    display: flex;
    flex-direction: column;
    padding: 0 130px 100px;
}

.how-tab-content-wrap .how-list-wrap {
    display: flex;
    flex-direction: column;
    max-width: 540px;
    margin: 0 auto 45px;
    gap: 24px;
}

.how-tab-content-wrap .how-tab-box-btn-wrap {
    margin: 0 auto 55px;
}

.how-tab-content-wrap .how-tab-box-btn-wrap .main-button {
    padding: 20px 40px;
    background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
}

.how-tab-content-wrap .how-tab-box-btn-wrap .main-button-text {
    font-size: 21px;
}

.how-tab-content-wrap .how-list-wrap .item {
    display: flex;
    align-items: center;
    gap: 24px;
}

.how-tab-content-wrap .how-list-wrap .item .label {
    display: flex;
    align-items: flex-end;
    gap: 6px;
}

.how-tab-content-wrap .how-list-wrap .item .label .id {
    font: 500 20px/1 var(--font-montserrat);
    color: #AD9952;
}

.how-tab-content-wrap .how-list-wrap .item .label .text {
    font: 400 14px/1.2 var(--font-montserrat);
    color: #FFFFFF;
}

.how-tab-content-wrap .video-wrap video {
    aspect-ratio: 886 / 1534;
    background-color: #fff;
    display: block;
    height: auto;
    margin: 0 auto;
    max-height: 500px;
    width: 289px;
}

.how-tab-content-wrap .how-faq-list {
    display: flex;
    flex-direction: column;
}

.how-tab-content-wrap .how-faq-list-item {
    display: flex;
    gap: 18px;
    padding: 25px;
    border-bottom: 2px solid rgba(255, 255, 255, .3);
}

.how-tab-content-wrap .how-faq-list-item .num {
    font: 700 21px/1 var(--font-montserrat);
    max-height: max-content;
    color: #CDAF46;
}

.how-tab-content-wrap .how-faq-list-item .num h3 {
    margin: 0;
}

.how-tab-content-wrap .how-faq-list-item .text h3 {
    font: 700 20px/1.3 var(--font-montserrat);
    margin: 0 0 18px;
    color: #FFFFFF;
}

.how-tab-content-wrap .how-faq-list-item .text p {
    font: 400 14px/1.3 var(--font-montserrat);
    color: #FFFFFF;
}

@media (max-width: 980px) {
    .how-section {
        padding: 0;
        max-width: 1000px;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, .7);
        margin: 0 auto 100px;
        position: relative;
    }
    
    .how-stars {
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
    
    .how-stars img {
        width: 100%;
        max-height: 120px;
    }
    
    .how-stars .how-stars-top {
        width: 100%;
        position: absolute;
        left: 0;
        top: -80px;
    }
    
    .how-stars .how-stars-bottom {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -60px;
    }
    
    .how-tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px;
        gap: 50px;
        position: relative;
        z-index: 2;
    }
    
    .how-tabs-content {
        position: relative;
        z-index: 2;
    }
    
    .how-tabs .how-title {
        font: 700 32px/1 var(--font-montserrat);
        position: relative;
        color: #FFFFFF;
        letter-spacing: 2px;
        cursor: pointer;
        text-align: center;
        max-width: max-content;
    }
    
    .how-tabs .how-title:first-child {
        width: 124px;
    }
    
    .how-tabs .how-title.active {
        color: rgba(255, 255, 255, .5);
    }
    
    .how-tabs .how-title.active::after {
        content: '';
        position: absolute;
        height: 5px;
        width: 100%;
        left: 0;
        bottom: -20px;
        display: block;
        background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
    }
    
    .how-tab-content-wrap {
        display: flex;
        flex-direction: column;
        padding: 0 0 60px;
    }
    
    .how-tab-content-wrap .how-list-wrap {
        display: flex;
        flex-direction: column;
        max-width: 360px;
        margin: 0 auto 60px;
        gap: 24px;
    }
    
    .how-tab-content-wrap .how-tab-box-btn-wrap {
        margin: 0 auto 55px;
    }
    
    .how-tab-content-wrap .how-tab-box-btn-wrap .main-button {
        padding: 20px 40px;
        background-image: linear-gradient(236deg, #A08935 -11.2%, #CDAF46 20.81%, #B99720 47.78%, #CFB146 74.86%, #C5A123 94.72%);
    }
    
    .how-tab-content-wrap .how-tab-box-btn-wrap .main-button-text {
        font-size: 21px;
    }
    
    .how-tab-content-wrap .how-list-wrap .item {
        display: flex;
        align-items: flex-start;
        gap: 24px;
    }
    
    .how-tab-content-wrap .how-list-wrap .item .label {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        padding-top: 8px;
    }
    
    .how-tab-content-wrap .how-list-wrap .item .label .id {
        font: 500 20px/1 var(--font-montserrat);
        color: #AD9952;
    }
    
    .how-tab-content-wrap .how-list-wrap .item .label .text {
        font: 400 14px/1.4 var(--font-montserrat);
        color: #FFFFFF;
    }
    
    .how-tab-content-wrap .how-faq-list {
        display: flex;
        flex-direction: column;
    }
    
    .how-tab-content-wrap .how-faq-list-item {
        display: flex;
        gap: 10px;
        padding: 25px;
        border-bottom: 2px solid rgba(255, 255, 255, .3);
    }
    
    .how-tab-content-wrap .how-faq-list-item .num {
        font: 700 24px/1 var(--font-montserrat);
        max-height: max-content;
        color: #CDAF46;
    }
    
    .how-tab-content-wrap .how-faq-list-item .num h3 {
        margin: 0;
    }
    
    .how-tab-content-wrap .how-faq-list-item .text h3 {
        font: 700 24px/1.2 var(--font-montserrat);
        margin: 0 0 10px;
        color: #FFFFFF;
    }
    
    .how-tab-content-wrap .how-faq-list-item .text p {
        font: 400 14px/1.3 var(--font-montserrat);
        color: #FFFFFF;
    }    
}